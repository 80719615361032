// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NYStreetStyle-module--CarrouselWrapper2--KgNra";
export var DescriptionWrapper = "NYStreetStyle-module--DescriptionWrapper--59UwA";
export var DownloaderWrapper = "NYStreetStyle-module--DownloaderWrapper--DozfS";
export var EventWrapper = "NYStreetStyle-module--EventWrapper--SoXkX";
export var ImageWrapper = "NYStreetStyle-module--ImageWrapper--F1296";
export var ImagesWrapper = "NYStreetStyle-module--ImagesWrapper--iHSeI";
export var MobileTile = "NYStreetStyle-module--MobileTile--Ng2Ee";
export var PdpWrapper = "NYStreetStyle-module--PdpWrapper--Y1dSx";
export var PhotosWrapper = "NYStreetStyle-module--PhotosWrapper--VbNoF";
export var TitleWrapper = "NYStreetStyle-module--TitleWrapper--G1LV7";
export var Wrapper = "NYStreetStyle-module--Wrapper--wq-T3";