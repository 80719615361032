import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./NYStreetStyle.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import NYStreetStylePdp from "./../../../../res/Affiche/Daze Ghost 2.jpeg";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import NYStreetStyle1 from "../../../../res/New York Street Style/insitu-1.jpg"
import NYStreetStyle2 from "../../../../res/New York Street Style/insitu-2.jpeg"
import NYStreetStyle3 from "../../../../res/New York Street Style/insitu-3.jpeg"
import NYStreetStyle4 from "../../../../res/New York Street Style/insitu-4.jpeg"
import NYStreetStylePdf from "../../../../res/New York Street Style/PresseNY.pdf"
import Tile from "../../../../res/New York Street Style/cover.png"

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: NYStreetStyle1, name: "NYStreetStyle" },
    { src: NYStreetStyle2, name: "NYStreetStyle" },
    { src: NYStreetStyle4, name: "NYStreetStyle" },
  ],
};

const NYStreetStyle = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="daze"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>NEW YORK STREET STYLE{/*<h2 style={{paddingLeft: "16px"}}>40 ans de création - Marseille - 2020</h2>*/}</h1>
        <br />
        <img className={MobileTile} src={Tile} alt="daze"/>
        <br />
        <p>
           Dans le New York des années 1970, en s’appropriant la ville, les artistes se forment autour d’une nouvelle culture qui leur est propre, qui possède ses propres codes, son propre langage, son propre style, son “attitude”.  A partir de ces codes métissés d’«enfants du ghetto» du Bronx ou de Brooklyn, ils lancent la culture hip-hop, du mouvement urbain, clandestin et protestataire qui englobe le DJing, le rap, le beatbox, le breakdance et le graffiti. Un laisser-aller savamment étudié dans le langage et dans le look a ouvert la nouvelle ère du design : celle du chic du décontracté. Le hip-hop a joué un rôle prépondérant, majeur dans l’histoire et la démocratisation des sneakers, et depuis 1985, la Jordan de Nike en est la star. Détournée par les rappeurs, revisitée par la mode, courtisée par le luxe, la J1 est devenue une véritable icône pop qui a tout bouleversé dans son sillage. Elle marque une rupture des frontières entre les pratiques et ouvre la voie à une nouvelle avant-garde artistique et esthétique. Cette jeunesse affranchie du poids de son histoire trouve écho aujourd'hui chez grand nombre de créateurs et d'artistes. C’est ce que la GHOST GALERIE tente de mettre en avant dans son exposition estivale 2022.
          </p>
          <br></br>
          <br></br>
          <a className={DownloaderWrapper} href={NYStreetStylePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a>
          <br />
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default NYStreetStyle;
